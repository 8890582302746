<template>
  <v-card class="elevation-6 pa-3 form-card">
    <v-card-text>
      <v-form>
        <v-flex class="d-flex justify-center" id="pick-avatar">
          <v-img
            max-height="200px"
            :src="company.icon"
            lazy-src="static/company.png"
            id="pick-avatar"
            @click="_onSelectButtonClick"
          >
            <template v-if="loading">
              <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="60"
                ></v-progress-circular>
              </v-layout>
            </template>
          </v-img>

          <input
            style="display: block"
            ref="imageInput"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
            @change="onChange"
          />
        </v-flex>

        <v-text-field label="Name" v-model="company.name"></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mt-5 btn_gradient"
        @click="_updateOrCreateCompany"
        :loading="loading"
      >
        Salvar
      </v-btn>
    </v-card-actions>
    <CropImageDialog
      title="Crop Image"
      :show="isCropImageDialogVisible"
      :image="imgSrc"
      :loading="loading"
      :dismiss-action="_closeCropDialog"
      :on-image-cropped="_onImageCropped"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CropImageDialog from '@/components/dialogs/CropImageDialog';
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  components: {
    CropImageDialog
  },
  data() {
    return {
      isCropImageDialogVisible: false,
      company: {},
      imgSrc: null,
      bannerUrl: null,
      videoUrl: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    confirmPasswordRule() {
      return [
        (value) => (!!value && value.trim().length > 0) || 'Required.',
        () =>
          this.model.confirmPassword === this.model.password ||
          'Password and Confirmation Password must match'
      ];
    }
  },
  methods: {
    ...mapActions({
      _updateCompany: 'updateCompany',
      _createCompany: 'createCompany',
      _getCompany: 'getCompany'
    }),

    async _updateOrCreateCompany() {
      this.loading = true;
      if (this.$route.params.id) {
        await this._updateCompany(this.company);
      } else {
        await this._createCompany(this.company);
      }
      this.loading = false;
      await this.$router.back();
    },

    onChange(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.isCropImageDialogVisible = true;
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    _onSelectButtonClick() {
      this.$refs.imageInput.click();
    },
    async _onImageCropped(file) {
      this.loading = true;
      try {
        const snapshot = await firebase
          .storage()
          .ref(`companies/${new Date().toISOString()}`)
          .put(file);
        this.company.icon = await snapshot.ref.getDownloadURL();
        this.isCropImageDialogVisible = false;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    _closeCropDialog() {
      this.isCropImageDialogVisible = false;
    }
  },
  async mounted() {
    const companyId = this.$route.params.id;
    if (companyId) {
      this.company = await this._getCompany(companyId);
    }
  }
};
</script>

<style>
input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.form-card {
  max-width: 450px;
  margin: 0 auto;
  box-shadow: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.theme--light.v-sheet {
  background-color: rgb(255 255 255 / 50%);
  border-radius: 30px;
  box-shadow: none !important;
}
.theme--light.v-sheet .white {
  background-color: transparent !important;
}
.v-toolbar__title h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  color: #fff;
  margin-top: 5px;
  margin-left: 6px;
}
</style>
